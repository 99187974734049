const Carousel = () => {
    return (
        <div className="container-fluid m-0 p-0 row w-100" >
            <div id="carouselExampleFade" className="carousel slide carousel-fade w-50 .col-sm-5 .col-md-6 p-0" data-bs-ride="carousel">
                <div className="carousel-inner">
                <div className="carousel-item active">
                <img src={'https://pp5michael.wd99p.com/image/carousel/karekare.jpg'} alt="img" className="inline-block w-100 h-100 object-fit-none border rounded" />
                </div>
                <div className="carousel-item">
                <img src={'https://pp5michael.wd99p.com/image/carousel/kawaliv2.jpg'} alt="img" className="d-inline-block w-100 h-100 object-fit-none border rounded" />
                </div>
                <div className="carousel-item">
                <img src={'https://pp5michael.wd99p.com/image/carousel/lobster.jpg'} alt="img" className="d-inline-block w-100 h-100 object-fit-none border rounded" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </div>
            <div id="carouselExampleFade1" className="carousel slide carousel-fade w-50 .col-sm-5 .col-md-6 p-0" data-bs-ride="carousel">
                <div className="carousel-inner">
                <div className="carousel-item active">
                <img src={'https://pp5michael.wd99p.com/image/carousel/tempura.jpg'} alt="img" className="inline-block w-100 h-100 object-fit-none border rounded" />
                </div>
                <div className="carousel-item">
                <img src={'https://pp5michael.wd99p.com/image/carousel/steak.jpg'} alt="img" className="d-inline-block w-100 h-100 object-fit-none border rounded" />
                </div>
                <div className="carousel-item">
                <img src={'https://pp5michael.wd99p.com/image/carousel/fruitsalad.jpg'} alt="img" className="d-inline-block w-100 h-100 object-fit-none border rounded" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade1" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade1" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </div>
        </div>
    );
}
export default Carousel;