import { useState, useEffect } from "react";
import Swal from "sweetalert2";
function Menu() {
    const [products, setProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("breakfast");
    const [orderCount, setOrderCount] = useState(null);
    const addToOrder = async (product) => {
        const response = await fetch(
            "https://pp5michaelbackend.wd99p.com/index.php?type=1",
            {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                mode: "cors",
                body: JSON.stringify({
                    product_id: product.id,
                    name: product.product_name,
                    price: product.price,
                    reservation_id: null,
                    qty: 1,
                }),
            }
        );
        if (!orderCount) {
            setOrderCount(1);
        } else {
            setOrderCount(orderCount + 1);
        }
        Swal.fire({
            title: "Successfully Added!",
            text: "Please visit orders page to schedule reservation",
            icon: "success",
        });
    };
    useEffect(() => {
        async function fetchData() {
            const res = await fetch(
                "https://pp5michaelbackend.wd99p.com/index.php?type=0",
                {
                    method: "GET",
                }
            );
            const result = await res.json();
            setProducts(result.data);
        }
        fetchData();
    }, []);
    const handleChangeCategory = (category) => {
        setSelectedCategory(category);
    };
    return (
        <div id="menu-section" className="container-fluid py-5 w-100">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="section-title ff-secondary text-center text-primary fw-normal">
                        Food Menu
                    </h5>
                    <h1 className="mb-5">Specially For You</h1>
                </div>
                <div className="tab-class text-center wow fadeInUp" data-wow-delay="0.1s">
                    <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
                        {[
                            { name: "Awesome", category: "breakfast", icon: "fa-coffee" },
                            { name: "Special", category: "lunch", icon: "fa-hamburger" },
                            { name: "Lovely", category: "dinner", icon: "fa-utensils" },
                            {
                                name: "Satisfying",
                                category: "pastries",
                                icon: "fa-hamburger",
                            },
                        ].map((item, index) => (
                            <li className="nav-item" key={index}>
                                <button
                                    className={`nav-link d-flex align-items-center text-start mx-3 ${
                                        item.category === selectedCategory ? "active" : ""
                                    }`}
                                    data-bs-toggle="pill"
                                    onClick={() => handleChangeCategory(item.category)}>
                                    <i className={`fa ${item.icon} fa-2x text-primary`}></i>
                                    <div className="ps-3">
                                        <small className="text-body">{item.name}</small>
                                        <h6 className="mt-n1 mb-0 text-capitalize">
                                            {item.category === "pastries"
                                                ? "pastries | desserts"
                                                : item.category}
                                        </h6>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content">
                        {["breakfast", "lunch", "dinner", "pastries"].map((category, index) => (
                            <div
                                id={`tab-${index + 1}`}
                                className={`tab-pane fade show p-0 ${
                                    category === selectedCategory ? "active" : ""
                                }`}
                                key={index}>
                                <div id={`container-${index + 1}`} className="row g-4">
                                    {products
                                        .filter(
                                            (product) =>
                                                product.category_name.toLowerCase() === category
                                        )
                                        .map((product, productIndex) => (
                                            <div className="col-lg-4 col-md-6 mb-4" key={productIndex}>
                                                <div className="card h-100">
                                                    <img
                                                        src={`/image/menu/${category}/${product.img_path}`}
                                                        className="card-img-top img-fluid object-fit-cover border rounded m-3 border border-warning"
                                                        alt={product.product_name}
                                                        style={{ height: "300px", width: "auto" }}
                                                    />
                                                    <div className="card-body d-flex flex-column">
                                                        <h5 className="card-title">{product.product_name}</h5>
                                                        <p className="card-text">{product.description}</p>
                                                        <div className="mt-auto">
                                                            <p className="text-primary fw-bold mb-0">
                                                                {product.price} php
                                                            </p>
                                                            <button
                                                                onClick={() => addToOrder(product)}
                                                                className="btn btn-warning btn-sm mt-2"
                                                            >
                                                                Add to Orders
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Menu;