import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
function OrderList() {
    const [reservationinfo, setReservationInfo] = useState({});
    const [products, setProducts] = useState([]);
    function reservationInfo(e) {
        setReservationInfo({
            ...reservationinfo,
            [e.target.name]: e.target.value
        });
    }
    const [orders, setOrders] = useState([]);
    async function changeProduct(id, e){
        let $el_select = e.currentTarget;
        let $id = $el_select.options[$el_select.selectedIndex].getAttribute('data-id');
        const response = await fetch("https://pp5michaelbackend.wd99p.com/index.php?type=2&id="+$id, {
            method: "GET",
        });
        const res = await response.json();
        let new_price = res.data[0].price;
        document.getElementById(id+'-price').innerText = new_price;
    }
    async function editOrder(id){
        let $el_select = document.getElementById(id+'-options');
        let $id = $el_select.options[$el_select.selectedIndex].getAttribute('data-id');
        const fetch_response = await fetch("https://pp5michaelbackend.wd99p.com/index.php?type=2&id="+$id, {
            method: "GET",
        });
        const res = await fetch_response.json();
        const data = res.data[0]
        const response = await fetch(
            "https://pp5michaelbackend.wd99p.com/index.php?type=0",
            {
            headers:{
                "Content-Type": "application/json"
            },
            method: "PATCH",
            mode: "cors",
            body: JSON.stringify({
                id: id,
                product_id: data.id,
                name: data.product_name,
                price: data.price,
                qty: 1
            }),
        })
        const res1 = await response.json();
        console.log(res1.data)
        fetchOrders()
        Swal.fire({
            title: "Success!",
            text: "The order is now updated",
            icon: "success"
        });
    }
    async function addReservation(e) {
        e.preventDefault();
        // Check if any required field is empty
        const requiredFields = ["name", "email", "date", "no_of_people", "phone_number", "address"];
        const isEmpty = requiredFields.some(field => !reservationinfo[field]);
        if (isEmpty) {
            console.log(reservationinfo)
            alert("Please fill out all required fields.");
            return;
        }
        setReservationInfo({ ...reservationinfo});
        const response = await fetch("https://pp5michaelbackend.wd99p.com/index.php?type=3", {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(reservationinfo),
        });
        const res = await response.json();
        const fetch_response = await fetch(
            "https://pp5michaelbackend.wd99p.com/index.php?type=1",
            {
            headers:{
                "Content-Type": "application/json"
            },
            method: "PATCH",
            mode: "cors",
            body: JSON.stringify({
                id : res.data[0].id 
            }),
        })
        const res1 = await fetch_response.json();
        // Show success message
        Swal.fire({
            title: "Success!",
            html: "You added a reservation! Reservation ID <b class='text-primary'>"+res.data[0].id +"</b>:Please take note of your reservation ID when coming to restaurant",
            icon: "success"
        }).then(function(){
            window.location.reload();
        });
        setReservationInfo({
            "name": "",
            "email": "",
            "date": "",
            "no_of_people": "",
            "phone_number": "",
            "address": "",
        });
    }
    async function fetchData() {
        const res = await fetch("https://pp5michaelbackend.wd99p.com/index.php?type=0",{
            method: "GET",
        });
        const result = await res.json();
        setProducts(result.data);
    }
    async function fetchOrders() {
        const res = await fetch("https://pp5michaelbackend.wd99p.com/index.php?type=1",{
            method: "GET",
        });
        const result = await res.json();
        setOrders(result.data);
    }
    async function deleteOrder(id){
        const response = await fetch(
            "https://pp5michaelbackend.wd99p.com/index.php",
            {
            headers:{
                "Content-Type": "application/json"
            },
            method: "DELETE",
            mode: "cors",
            body: JSON.stringify({
                id : id
            }),
        })
        fetchOrders()
        Swal.fire({
            title: "Success!",
            text: "The order is now removed",
            icon: "success"
        });
        setReservationInfo({
            "name": "",
            "email": "",
            "date": "",
            "no_of_people": "",
            "phone_number": "",
            "address": "",
        });
    }
    useEffect(() => {
        fetchData();
        fetchOrders();
    }, [])
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 bg-dark py-4">
                        <h5 className="section-title ff-secondary text-start text-primary fw-normal">Reservation</h5>
                            <h1 className="text-white mb-4">Book Now!</h1>
                            <form action="POST">
                                <div className="row g-3 py-4">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input required type="text" className="form-control" id="name" name="name" placeholder="Your Name" value={reservationinfo.name} onChange={(e) => reservationInfo(e)}/>
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">  
                                        <div className="form-floating">
                                            <input required type="text" className="form-control" id="address" name="address" placeholder="Your Address" value={reservationinfo.address} onChange={(e) => reservationInfo(e)}/>
                                            <label for="address">Your Address</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input required type="number" className="form-control" id="phone_number" name="phone_number" placeholder="Your Phone Number" value={reservationinfo.phone_number} onChange={(e) => reservationInfo(e)}/>
                                            <label for="phone_number">Phone Number</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating date" id="date3" data-target-input="nearest">
                                            <input required type="datetime-local" className="form-control datetimepicker-input" id="date" name="date" placeholder="Date & Time" data-target="#date3" data-toggle="datetimepicker" value={reservationinfo.date} onChange={(e) => reservationInfo(e)} />
                                            <label for="date">Date & Time</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">  
                                        <div className="form-floating">
                                            <input required type="email" className="form-control" id="email" name="email" placeholder="Your Email" value={reservationinfo.email} onChange={(e) => reservationInfo(e)}/>
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input required className="form-control form-control-input" type="number" name="no_of_people" value={reservationinfo.no_of_people} onChange={(e) => reservationInfo(e)}/>
                                            <label for="no_of_people">No Of People</label>
                                          </div>
                                    </div>
                                    <div className="col-12">
                                        <button onClick={(e) => addReservation(e) } className="btn btn-primary w-100 py-3" type="submit">Book Now</button>
                                    </div>
                                </div>
                            </form>
                    </div>
                    <div className="col-md-6 bg-dark py-4">
                        <h5 className="section-title ff-secondary text-start text-primary fw-normal">Menu Order Request</h5>
                        <div className="table-responsive">
                            <table className="table table-borderless text-primary fw-normal">
                                <thead>
                                    <tr>
                                <th scope="col">Order ID</th>
                                <th scope="col">Order Qty</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Price</th>
                                <th scope="col" className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {orders.map( order=>(
                                                    <tr>
                                                        <td className="align-middle">{ order.id }</td>
                                                        <td className="align-middle w-auto"><input className="quantity bg-secondary text-white text-center" style={{ width: "45px" }} id={order.id + '-qty'} type="number" min="1" value={ order.qty } /></td>
                                                        <td className="align-middle">
                                                            <select name="selected_name" id={order.id + '-options'} class="form-control" onChange={(e) => {changeProduct(order.id, e)}}>
                                                                {products.map( product => (
                                                                    <option value={product.product_name} {...(order.product_id == product.id) && {selected : 'selected'}} data-id={product.id}>{product.product_name}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td className="align-middle" id={order.id + '-price'}>{ order.price }</td>
                                                        <td className="align-middle d-flex">
                                                        <button className='btn btn-light btn-sm text-black fw-normal p-2 mx-2' onClick={() => {editOrder(order.id)}} >Update</button>
                                                        <button className='btn btn-danger btn-sm text-white fw-normal p-2' onClick={() => {deleteOrder(order.id)}}>Delete</button>
                                                        </td>
                                                    </tr>
                                                    ))}
                                    </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
export default OrderList;