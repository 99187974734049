import React from "react";
import './components/styles.css';
// import Navbar from "./components/Navbar";
// import Menu from "./components/Menu";
// import About from "./components/About";
// import Home from "./components/Home";
// import Footer from "./components/Footer";
// import Carousel from "./components/Carousel";
import Layout from "./common/Layout";
// import Footer from "./components/Footer";
// import RouterApp from "./router/RouterApp";
// import Carousel from "./components/Carousel";
function App() {
  return (
  <div className="App">
    <Layout />
  </div>
  );  
}
export default App;