import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import "./styles.css";
const Navbar = () => {
const homeRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);  
  }, []);
  return (     
    <nav className="container-fluid navbar navbar-expand-sm navbar-dark bg-dark sticky-top w-100" >
      <Link to="/" className="navbar-brand p-0 m-0">
        <h1 className="text-primary m-0 pt-3"><i className="fa fa-utensils text-primary"></i> PHcuisine </h1>
      </Link>
      <button className="navbar-toggler" title="title" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
        <span className="fa fa-bars"></span>   
      </button>
      <div className="collapse navbar-collapse" id="collapsibleNavbar" style={{ maxWidth: "100%", overflow: "hidden"}}>
        <div className="navbar-nav ms-auto py-0 pe-4">
          <Link to="/" className="selected1 nav-item nav-link text-white pb-0 m-0" id="home-nav" href=''>Home</Link>
          <Link to="/about" className="selected1 nav-item nav-link text-white pb-0 m-0" id="about-nav">About</Link>
          <Link to="/services" className="selected1 nav-item nav-link text-white pb-0 m-0" id="services-nav">Services</Link>
          <Link to="/menu" className="selected1 nav-item nav-link text-white pb-0 m-0" id="menu-nav" >Menu</Link>
          <a href="#contact-us" className="selected1 nav-item nav-link text-white pb-0 m-0" id="contact-nav" style={{ margin: '0px 25px'}}>Contact</a>
          <div className='selected2 pt-4'>
            <Link to="/login" className="selected nav-item nav-link text-white p-1 btn btn-primary m-0" id="login-nav" style={{ height: '35px', width: '70px'}} >Login</Link>
          </div>
          <div className='selected2 pt-4'>
            <Link to="/register" className="selected nav-item nav-link text-white p-1 btn btn-primary m-0" id="register-nav" style={{ height: '35px', width: '100px'}} >Register</Link>
            </div>
          <div className='pt-4'>
          <Link to="/orders" className="selected btn btn-success p-1 m-0" id="orders-nav" style={{ height: '35px', fontFamily: 'Nunito, sans-serif'}}><i className="fa fa-shopping-cart " ></i>
              <span>Orders</span>
          </Link>
        </div>
        <div className="dropdown pt-4">
          <button className="btn btn-secondary dropdown-toggle p-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: '35px', margin: '0px 6px' }}>
            <span><i className="fa fa-search"></i></span> Track orders 
          </button>
          <ul className="dropdown-menu">
            <div className="d-flex dropdown-item input-group input-group-sm">  
              <input className="form-control" type="text" placeholder="Enter reservation id" />
              <i className="fa fa-arrow-right ml-3 my-auto"></i>
            </div>
          </ul>
        </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;