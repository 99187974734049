import React from "react";
import { Routes, Route} from 'react-router-dom';
import About from "../components/About";
import Home from "../components/Home";
import Services from "../components/Services";
import Menu from "../components/Menu";
import ReservationForm from "../components/ReservationForm";
import OrderList from "../components/OrderList";
import Login from "../components/Login";
import Register from "../components/Register";
const RouterApp = () => {
    return (
        <Routes>
              {/* Home */}
              <Route path="/" element={<Home />} />
              {/* About */}
              <Route path="/about" element={<About />} />
              {/* Menu */}
              <Route path="/menu" element={<Menu />}/>
              {/* Orders */}
              <Route path="/orders" element={<OrderList />}/>
              {/* Services */}
              <Route path="/services" element={<Services />} />
              {/* Login */}
              <Route path="/login" element={<Login />} />
              {/* Register */}
              <Route path="/register" element={<Register />} />
              {/* Reservation */}
              <Route path="/reservation" element={<ReservationForm />}/>  
        </Routes>
    );
  };
  export default RouterApp;