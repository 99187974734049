import React, { useState } from 'react';
import axios from 'axios';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://localhost:8000/api/login', {
        email,
        password
      });
      console.log(response.data); // handle success, e.g., store token and redirect
    } catch (error) {
      setError(error.response.data.message); // handle error, e.g., show error message
    }
  };
  return (
    <div className='mb-5'>
      <div className="container mt-5 w-50 p-3 p-md-5 border border-warning">
        <h2 className='p-4 m-auto text-center'>Login</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email address</label>
            <input type="email" className="form-control" id="email" placeholder="Enter email" value={email} onChange={handleEmailChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
          </div>
          <div className="mb-3 d-grid">
            <button type="submit" className="btn btn-primary btn-block">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;