import React, { useState } from 'react';
import Swal from 'sweetalert2';
function Footer() {
  const [email, setEmail] = useState ("");
  function addEmail(e) {
      setEmail(e.target.value);
  }
  async function addToNewsLetter(e) {
    e.preventDefault();
    async function saveNewsletter() {
        const response = await fetch(
            "https://pp5michaelbackend.wd99p.com/index.php?type=0",
            {
            headers:{
                "Content-Type": "application/json"
            },
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                email: email,
            }),
        }       
    );
    const res = await response.json()
        // Show success message
        Swal.fire({
            title: "Success!",
            text: "You are now subscribed to our newsletter!",
            icon: "success"
        });
        setEmail("")
    }
    saveNewsletter();
  }
  return (
    <div id="contact-us" className="container-fluid bg-dark text-light footer pt-0 mt-0 wow fadeIn w-100" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Company</h4>
            <a className="text-light fw-normal" href="/about">About Us</a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Contact</h4>
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Malolos City, Bulacan, Philippines</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+639225078248</p>
            <p className="mb-2"><i className="fa fa-envelope me-3"></i>PHcuisine@gmail.com</p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="https://x.com/johnmic76791492"target="_blank"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/johnmichael.delacruz03" target="_blank"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/" target="_blank"><i className="fab fa-youtube"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/in/john-michael-dela-cruz-9498671b1" target="_blank"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Store Hours</h4>
            <p className="text-light fw-normal">Monday - Friday</p>
            <p>07:00 AM - 09:00 PM</p>
            <p className="text-light fw-normal">Saturday</p>
            <p>07:00AM - 12:00PM(cut-off)</p>
            <p className="text-light fw-normal">Sunday</p>
            <p>CLOSED</p>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Newsletter</h4>
            <p>Join our exclusive newsletter community today and stay up-to-date with the latest culinary trends, mouth-watering recipes, and insider tips from our master chefs. Sign up now and let the culinary adventure begin!</p>
            <div className="position-relative mx-auto" style={{ maxWidth: '400px' }}>
              <form action="POST">
              <input className="form-control border-primary w-100 py-3 ps-4 pe-5" required type="text" placeholder="Your email" id="email" name="email" value={email}  onChange={(e) => addEmail(e)} />
              <button onClick={(e) => addToNewsLetter(e) } type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col text-center text-md-center mb-4">
              &copy; <a className="border-bottom" href="#">PHcuisine</a>, All Right Reserved 2024. 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;