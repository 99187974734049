import React, { useState } from "react";
import Swal from 'sweetalert2';
const Reservation = () => {
    const [reservationinfo, setReservationInfo] = useState({});
    function reservationInfo(e) {
        setReservationInfo({
            ...reservationinfo,
            [e.target.name]: e.target.value
        });
    }
    async function addReservation(e) {
        e.preventDefault();
        // Check if any required field is empty
        const requiredFields = ["name", "email", "date", "no_of_people", "phone_number", "address", "order_request"];
        const isEmpty = requiredFields.some(field => !reservationinfo[field]);
        if (isEmpty) {
            console.log(reservationinfo)
            alert("Please fill out all required fields.");
            return;
        }
        setReservationInfo({ ...reservationinfo});
        const response = await fetch("http://localhost:8080/reservations", {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(reservationinfo),
        });
        // Show success message
        Swal.fire({
            title: "Success!",
            text: "You added a reservation!",
            icon: "success"
        });
        setReservationInfo({
            "name": "",
            "email": "",
            "date": "",
            "no_of_people": "",
            "phone_number": "",
            "address": "",
            "order_request": ""
        });
    }
    return (
        <div class="container-fluid py-0 px-0 wow fadeInUp" data-wow-delay="0.1s">
            <div class="row g-0 h-100">
                <div class="col-md-6 h-100">
                <div class="row-col-md-3">
                    <img src="./image/reserved2.jpg" alt="Reservation" className="img-fluid h-50" style={{ width: '100%' }} />
                </div>
                <div class="row-col-md-3">
                    <img src="./image/reserve.jpg" alt="Reservation" className="img-fluid h-50" style={{ width: '100%' }} />
                </div>
                </div>
                <div class="col-md-6 bg-dark h-auto d-inline-block w-50">
                    <div class="px-5 wow fadeInUp h-50" data-wow-delay="0.2s" style={{paddingTop: '120px'}}>
                        <h5 class="section-title ff-secondary text-start text-primary fw-normal">Reservation</h5>
                        <h1 class="text-white mb-4">Book Now!</h1>
                        <form action="POST">
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input required type="text" class="form-control" id="name" name="name" placeholder="Your Name" value={reservationinfo.name} onChange={(e) => reservationInfo(e)}/>
                                        <label for="name">Your Name</label>
                                    </div>
                                </div>
                                <div class="col-md-6">  
                                    <div class="form-floating">
                                        <input required type="text" class="form-control" id="address" name="address" placeholder="Your Address" value={reservationinfo.address} onChange={(e) => reservationInfo(e)}/>
                                        <label for="address">Your Address</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input required type="number" class="form-control" id="phone_number" name="phone_number" placeholder="Your Phone Number" value={reservationinfo.phone_number} onChange={(e) => reservationInfo(e)}/>
                                        <label for="phone_number">Phone Number</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating date" id="date3" data-target-input="nearest">
                                        <input required type="datetime-local" class="form-control datetimepicker-input" id="date" name="date" placeholder="Date & Time" data-target="#date3" data-toggle="datetimepicker" value={reservationinfo.date} onChange={(e) => reservationInfo(e)} />
                                        <label for="date">Date & Time</label>
                                    </div>
                                </div>
                                <div class="col-md-6">  
                                    <div class="form-floating">
                                        <input required type="email" class="form-control" id="email" name="email" placeholder="Your Email" value={reservationinfo.email} onChange={(e) => reservationInfo(e)}/>
                                        <label for="email">Your Email</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input required class="form-control form-control-input" type="number" name="no_of_people" value={reservationinfo.no_of_people} onChange={(e) => reservationInfo(e)}/>
                                        <label for="no_of_people">No Of People</label>
                                      </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating">
                                        <textarea required class="form-control" placeholder="Order Request" id="order_request" name="order_request" style={{height: '100px'}} value={reservationinfo.order_request} onChange={(e) => reservationInfo(e)}></textarea>
                                        <label for="order_request">Menu Order Request</label>                
                                    </div>          
                                </div>
                                <div class="col-12">
                                    <button onClick={(e) => addReservation(e) } class="btn btn-primary w-100 py-3" type="submit">Book Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
};
export default Reservation;